export let BASE_URL_API;

if (process.env.NODE_ENV == "development") {
  BASE_URL_API = "http://localhost:8081/api/v1/";
  // BASE_URL_API = "https://msi-orcamento-api.herokuapp.com/api/v1/";
} else {
  BASE_URL_API = "https://api.orcamento.b4sis.com.br/api/v1/";
}

export const API_CODE = "orcamento-msi";
export const JWT_SECRET = "482d648a75fc3d81f9e30a01aa3e9a6a9572b05e9347eafef034a0728b676b5a"
