/*eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import Login from "./modules/Login";

Vue.use(Vuex);

const persisteState = createPersistedState({
  paths: ["Login"],
});

export default new Vuex.Store({
  plugins: [persisteState],
  modules: {
    Login,
  },
});
