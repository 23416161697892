/*eslint-disable */
import router from "../../router";
import axios from "axios";
import { BASE_URL_API } from "../../config/config";

const state = {
  auth: null,
  loginLoading: false,
  loginError: "",
  refreshTokenError: "",
};

const mutations = {
  SET_AUTH: (state, value) =>
    value ? (state.auth = value) : (state.auth = null),
  SET_LOGIN_LOADING: (state, value) =>
    value ? (state.loginLoading = value) : (state.loginLoading = false),
  SET_LOGIN_ERROR: (state, value) =>
    value ? (state.loginError = value) : (state.loginError = ""),
  SET_REFRESH_TOKEN_ERROR: (state, value) =>
    value ? (state.refreshTokenError = value) : (state.refreshTokenError = ""),
  SET_LOGOUT: (state) => {
    state.auth = null;
    state.loginLoading = false;
    state.loginError = "";
    state.refreshTokenError = "";
  },
};

const getters = {
  getAuth: (state) => state.auth,
  getLoginLoading: (state) => state.loginLoading,
  getLoginError: (state) => state.loginError,
  getRefreshTokenError: (state) => state.refreshTokenError,
  isAdmin: (state) =>
    state.auth &&
    state.auth.roles &&
    state.auth.roles.length > 0 &&
    state.auth.roles.includes("ADMIN"),
};

const actions = {
  async loginUsuarioSenha({ commit }, payload) {
    commit("SET_LOGIN_LOADING", true);

    return axios({
      method: "POST",
      url: `${BASE_URL_API}auth/login`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        commit("SET_LOGIN_ERROR");
        commit("SET_LOGIN_LOADING");
        commit("SET_AUTH", response.data);
      })
      .catch(function (error) {
        commit("SET_LOGIN_LOADING");
        commit("SET_LOGIN_ERROR", error.response.data.title);
      });
  },
  logout({ commit }) {
    commit("SET_LOGOUT");
    router.push({
      name: "Login",
      params: { id: router.currentRoute.params.id },
    });
  },

  async refreshToken({ commit, state }, value) {
    const data = { refreshToken: value };
    return axios({
      method: "POST",
      url: `${BASE_URL_API}auth/refreshtoken`,
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const { accessToken, refreshToken } = response.data;
        const auth = { ...state.auth, accessToken, refreshToken };
        commit("SET_AUTH", auth);
      })
      .catch((error) => commit("SET_REFRESH_TOKEN_ERROR", error.response.data));
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
