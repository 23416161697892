import jwt from "jsonwebtoken";

const tokenValid = (token) => {
  try {
    const decoded = jwt.decode(token);
    if (Date.now() < decoded.exp * 1000) {
      return true;
    }
  } catch (err) {
    return false;
  }
};

export default tokenValid;
