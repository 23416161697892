import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pt from 'vuetify/lib/locale/pt'
import VuetifyConfirm from "vuetify-confirm";

Vue.use(Vuetify);
const vuetify = new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt',
  },
  theme: {
    themes: {
      light: {
        primary: '#41A9AA',
      },
    },
  },
});

Vue.use(VuetifyConfirm, { vuetify });
export default vuetify;
