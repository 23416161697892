import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Notifications from "vue-notification";
import VuetifyMoney from "vuetify-money";
import VueTheMask from "vue-the-mask";

import "./helpers/filters";

Vue.config.productionTip = false;

Vue.use(VuetifyMoney);
Vue.use(Notifications);
Vue.use(VueTheMask);
Vue.directive("uppercase", {
  update: function (el, binding) {
    console.log(binding.value);
    el.value = el.value.toUpperCase();
  },
});
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
