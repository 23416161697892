import Vue from "vue";
import moment from "moment";

Vue.filter("localDateToString", (data) => {
  if (!data) return "";
  //if (!moment(data).isValid()) return "";
  return moment(data, 'YYYY MM DD').format("DD/MM/YYYY");
});

Vue.filter("formataMoeda", (item) => {
  if (!item) return 0
  return item.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
});
