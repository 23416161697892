<template>
  <router-view> </router-view>
</template>

<script>
export default {};
</script>
<style>
@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
}
</style>