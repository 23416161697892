import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";
import middleware from "./middleware";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: () => {
      if (!store.getters.isAdmin) return { name: "AprovarOrcamento" };

      return { name: "Contas" };
    },
    component: () => import(/* webpackChunkName: "home" */ "../layouts/"),
    children: [
      {
        path: "contas",
        name: "Contas",
        meta: { admin: true, requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "contas" */ "../views/contas/"),
      },
      {
        path: "projeto",
        name: "Projeto",
        meta: { admin: true, requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "projeto" */ "../views/projeto/"),
      },
      {
        path: "projeto-finalizado",
        name: "ProjetoFinalizado",
        meta: { admin: true, requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "projetoFinalizado" */ "../views/projeto/finalizado.vue"),
      },
      {
        path: "orcamento",
        name: "Orcamento",
        meta: { admin: true, requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "orcamento" */ "../views/orcamento/"),
      },
      {
        path: "clientes",
        name: "Clientes",
        meta: { admin: true, requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "clientes" */ "../views/clientes/"),
      },
      {
        path: "fornecedores",
        name: "Fornecedores",
        meta: { admin: true, requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "fornecedores" */ "../views/fornecedores/"
          ),
      },
      {
        path: "aprovarorcamento",
        name: "AprovarOrcamento",
        meta: { admin: false, requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "aprovarorcamento" */ "../views/aprovar_orcamento/"
          ),
      },
      // {
      //   path: "contaspagar",
      //   name: "ContasPagar",
      //   meta: { admin: false, requiresAuth: true },
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "contaspagar" */ "../views/contas_pagar/"
      //     ),
      // },
      {
        path: "contasreceber",
        name: "ContasReceber",
        meta: { admin: false, requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "contasreceber" */ "../views/contas_receber/"
          ),
      },
      {
        path: "empresa",
        name: "Empresa",
        meta: { admin: false, requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "empresa" */ "../views/empresa/"),
      },
      {
        path: "usuario",
        name: "Usuario",
        meta: { admin: false, requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "usuario" */ "../views/usuario/"),
      },
      {
        path: "alterar-senha",
        name: "alterar-senha",
        meta: { admin: false, requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "alterar-senha" */ "../views/alterar_senha/"
          ),
      },
      {
        path: "login",
        name: "Login",
        component: () => import(/* webpackChunkName: "login" */ "../views/login/"),
      },

      {
        path: "relatorio-contas-periodo",
        name: "RelatorioContasPeriodo",
        meta: { admin: false, requiresAuth: true },
        component: () =>
          import(/* webpackChunkName: "RelatorioContasPeriodo" */ "../views/relatorio/contasPeriodo"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(function (to, _, next) {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (
      to.matched.some((route) => route.meta.admin) &&
      !store.getters.isAdmin
    ) {
      store.dispatch("logout");
      //next({ path: "/login" });
    }
    if (
      store.getters.getAuth &&
      middleware(store.getters.getAuth.accessToken)
    ) {
      next();
    } else {
      store.dispatch("logout");
      next({ path: to.params.id });
    }
  } else {
    next();
  }
});

export default router;
